// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 10 indigo-coolGrey ==========================================
:root {
  // PRIMARY COLOR
  --c-primary-50: 255, 255, 255;
  --c-primary-100: 253, 236, 236;
  --c-primary-200: 250, 212, 212;
  --c-primary-300: 246, 188, 188;
  --c-primary-400: 242, 163, 163;
  --c-primary-500: 238, 138, 138;
  --c-primary-600: 233, 114, 114;
  --c-primary-700: 219, 95, 95;
  --c-primary-800: 197, 77, 77;
  --c-primary-900: 245, 0, 0;

  // SECONDARY COLOR
  --c-secondary-50: 255, 255, 255;
  --c-secondary-100: 248, 252, 250;
  --c-secondary-200: 242, 248, 245;
  --c-secondary-300: 227, 243, 236;
  --c-secondary-400: 178, 227, 210;
  --c-secondary-500: 131, 206, 183;
  --c-secondary-600: 94, 176, 158;
  --c-secondary-700: 76, 141, 133;
  --c-secondary-800: 66, 117, 111;
  --c-secondary-900: 255, 142, 6;

  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 31, 41, 55;
  --c-neutral-900: 17, 24, 39;
}
