.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Change text color for the phone input field */
.custom-phone-input {
  color: #ff0000;
  /* Change to your desired text color */
}

/* Change text color for the country selection button */
.custom-phone-button {
  color: #ff0000;
  /* Change to your desired text color */
}

/* Change text color for the country dropdown list */
.custom-phone-dropdown .country-list .country {
  color: #ff0000;
  /* Change to your desired text color */
}

/* Optionally, customize hover and selected styles */
.custom-phone-dropdown .country-list .country:hover,
.custom-phone-dropdown .country-list .country.highlight {
  background-color: #f0f0f0;
  /* Background color on hover */
  color: #000;
  /* Text color on hover */
}

.whatsapp-floating-button {
  position: fixed;
  bottom: 20px;
  /* Adjust position from the bottom */
  right: 20px;
  /* Adjust position from the right */
  z-index: 1000;
  /* Ensure it appears above other content */
  background-color: #25d366;
  /* WhatsApp green */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.whatsapp-icon {
  width: 60px;
  /* Adjust size as needed */
  height: 60px;
  /* Adjust size as needed */
}
/* Add this to your CSS file */.review-card {
  width: 100%; /* Make it responsive */
  max-width: 250px; /* Set a maximum width */
  height: auto; /* Allow height to adjust based on content */
  max-height: 350px; /* Set a maximum height */
  transition: transform 0.2s;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding: 16px; /* Add some padding */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 8px; /* Rounded corners */
  background-color: white; /* Background color */
  overflow: hidden; /* Hide overflow */
}

.review-card:hover {
  transform: scale(1.05);
}

.stars {
  color: #f39c12; /* Gold color for stars */
}

.star {
  font-size: 20px; /* Adjust star size */
}

.star.filled {
  color: #f39c12; /* Gold color for filled stars */
}

.star:not(.filled) {
  color: #ccc; /* Gray color for empty stars */
}

.review-text {
  flex-grow: 1; /* Allow the text to grow */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  display: -webkit-box; /* For WebKit browsers */
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical; /* Set box orientation */
  margin-top: 8px; /* Add some margin */
  color: #333; /* Text color */
}

.ads-container {
  display: flex;
  overflow: hidden; /* Hide overflow to create a clean look */
}

.ads-card {
  flex: 0 0 320px; /* Adjust based on your card width */
  margin-right: 16px; /* Space between cards */
}