@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}

.las.la-shopping-cart {
  font-size: 24px; /* Example size */
  color: #000000; /* Example color */
}

.text-shadow-md {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.user-profile-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  grid-column: span 2;
  padding: 10px 20px;
  background-color: #0056b3; /* Your brand color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.add-button {
  margin-top: 10px;
  background-color: #28a745; /* A greenish color for the button */
  color: white; /* White text to contrast the green background */
  border: 2px solid #28a745; /* Green border to match the button color */
  padding: 8px 16px;
  border-radius: 20px; /* Pill shape */
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}

.add-button:hover,
.add-button:focus {
  background-color: #28a745; /* Maintain the same color on hover/focus */
  color: white;
}

h2 {
  margin-bottom: 20px;
}

.input-field,
.submit-button,
.add-button {
  color: #eee; /* Soft white text for better readability on dark blue backgrounds */
}

.input-field {
  width: 100%;
  padding: 10px;
  background-color: #16213e; /* Dark blue for input fields */
  border: 1px solid #0f3460; /* Blue border for some depth */
  border-radius: 4px;
}

.submit-button {
  grid-column: span 2;
  padding: 10px 20px;
  background-color: #0f3460; /* A vivid blue for submit buttons */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.add-button {
  margin-top: 10px;
  background-color: #1a1a2e; /* Slightly darker blue for add buttons */
  border: 1px solid #0f3460; /* Border to match the submit button */
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.add-button:hover,
.submit-button:hover {
  opacity: 0.8;
}

.user-info-section,
.vehicles-section,
.addresses-section {
  padding: 20px;
  background-color: #16213e; /* Consistent dark blue background for sections */
  border-radius: 4px;
}

h2 {
  color: #a4b0be; /* Lighter shade for the section headings for contrast */
}

/* Further enhance the labels for more visibility */
label {
  display: block;
  margin-bottom: 0.5rem;
  color: #dcdde1; /* Lighter color for labels */
}

/* You might also want to ensure inputs and text are visible */
.input-field {
  background-color: #1c2833; /* Dark input fields for contrast */
  color: #dcdde1; /* Light color text for readability */
  /* Rest of the input-field styles */
}

/* Adjust placeholder color for visibility */
.input-field::placeholder {
  color: #a4b0be; /* Lighter placeholder text */
}
/* Enhance visibility of icons with a lighter blue */
.fa-trash,
.fa-plus {
  color: #4e9f3d; /* Adding a contrasting color for the icons can be a good choice */
}

/* Style the checkboxes to fit the dark theme with blue tones */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0f3460;
  border-color: #0f3460;
}

.red-text {
  color: #ff8e06 !important;
}

.custom-phone-input {
  color: #ff0000; /* Change to your desired text color */
}
